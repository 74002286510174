import styled from "styled-components";
import { StyledForm, StyledField, StyledButton } from "../Riot/EmailForm/style";
import { FormErrorMessage, MessageWrapper } from "../Riot/EmailForm/components/Error/style";
import { colors, breakpoints, eases } from "../../styles/variables";

export const NewsletterSection = styled.section`
    position: relative;
    padding-top: 17vw;
    margin: -10vw 0 0 0;

    .newsletterGrid {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .titleBox {
        grid-column: 2 / span 10;
        grid-row: 1;
        color: ${colors.cream};

        .text-header {
            margin-bottom: 64px;
            text-align: left;
        }

        .text-miniheader {
            margin-bottom: 64px;
            text-transform: uppercase;
        }

    }
    @media (max-width: ${breakpoints.tablet}) {
        .newsletterGrid {
            padding-top: 100px;
            padding-bottom: 100px;
        }
        .titleBox {
            grid-column: 2 / span 6;
        }
    }

    @media (max-width: ${breakpoints.mobile}) {
        .titleBox {
            grid-column: 1 / span 2;

            .text-header, .text-miniheader {
                margin-bottom: 24px;
            }
        }
    }
`

export const backgroundImage = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
    width: "100%",
    height: "100%",
} as const

export const FormContainer = styled.div`
    position: relative;
    grid-column: 2 / span 10;
    grid-row: 2;

    ${StyledForm} {
        display: block;
        padding: 0;

        .post-signup {
            width: 100%;
            height: initial;
            min-width: initial;
            max-width: initial;
            min-height: initial;
            padding: 0;
            border: 0;
            background: transparent;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 24px;
            font-family: inherit;
        }

        .post-signup-content {
            width: 100%;
            padding: 48px 72px;
            background: ${colors.bright02};
            border: 4px solid ${colors.primary};
            box-shadow: 8px 8px 0 ${colors.primary};
            color: ${colors.white};

            h1 {
                font-size: 22px;
            }

            @media (max-width: ${breakpoints.tablet}) {
                padding: 28px 42px;

                h1 {
                    font-size: 18px;
                }
            }
        }

        .signup {
            width: initial;
            height: initial;
            min-width: initial;
            max-width: initial;
            min-height: initial;
            padding: 0;
            border: 0;
            background: transparent;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 24px;
            font-family: inherit;

            h1 {
                display: none;
            }
        }
    }

    ${StyledField} {
        margin: 0;
        padding: 0;
        flex: 2;

        label {
            color: ${colors.smoke};
        }

        label.floating {
            opacity: 0.6;
        }

        input[type="text"] {
            display: block;
            height: 54px;
            border: none;
            border-radius: 0;
            background-color: ${colors.cream};
            font-family: "ProximaNova-Bold";
            font-size: 17px;
            letter-spacing: 0;
            color: ${colors.smoke};
            -webkit-text-fill-color: ${colors.smoke};
            -webkit-box-shadow: 0 0 0px 1000px ${colors.cream} inset;

            ::placeholder {
                font-family: "ProximaNova-Bold";
                font-size: 17px;
            }

            :-webkit-autofill::first-line {
                font-family: "ProximaNova-Bold";
                font-size: 17px;
            }
        }

        input[type="text"].error {
            outline: 3px solid ${colors.lilac};
            outline-offset: -5px;

            @media (max-width: ${breakpoints.tablet}) {
                margin-bottom: 10px;
            }
        }

        @media (max-width: ${breakpoints.tablet}) {
            flex: 1 0 100%;
        }
    }

    ${StyledButton} {
        margin: 0;
        border-radius: 0;

        display: inline-block;
        font-family: "ProximaNova-Black";
        font-size: 20px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        padding: 12px 32px;
        cursor: pointer;
        position: relative;

        // Default is lime theme
        color: ${colors.white};
        border: 4px solid ${colors.primary};
        box-shadow: 4px 4px ${colors.primary};

        background-size: 250% 100%;
        background-position: 0 0;
        background-image: linear-gradient(30deg, ${colors.bright01} 50%, ${colors.white} 51%);

        transition:
            all 0.2s ease-out,
            color 0.3s ease-out,
            background-position 0.5s ${eases.inOut};

        &:hover {
            transform: translate(2px, 2px);
            background-position: 100% 0;
            color: ${colors.bright01};
            box-shadow: 2px 2px ${colors.bright01};
            opacity: 1;
        }
        &:active {
        }
        &:disabled {
            opacity: 0.5;
            pointer-events: none;
            box-shadow: none;
        }
    }

    ${MessageWrapper} {
        @media (max-width: ${breakpoints.tablet}) {
            position: relative;
        }
    }

    ${FormErrorMessage} {
        padding-top: 10px;
        color: #fff;
        font: inherit;

        .img-container {
            display: none;
        }

        @media (max-width: ${breakpoints.tablet}) {
            padding: 0;
        }
    }


    @media (max-width: ${breakpoints.tablet}) {
        grid-column: 2 / span 6;

        form input {
            flex: 3;
        }
    }

    @media (max-width: ${breakpoints.mobile}) {
        grid-column: 1 / span 2;
    }
`


export const BirthdayInputContainer = styled.div`
    flex: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${colors.cream};
    font-family: "ProximaNova-Regular";
    font-size: 16px;
    line-height: 170%;
    padding: 0 12px;
    margin-right: 24px;
    margin-bottom: 10px;

    input {
        background-color: transparent;
        border: none;
        width: 50px;
        padding: 17px 0 17px 12px;
    }

    @media (max-width: ${breakpoints.tablet}) {
        flex: 1 1 100%;
        margin-right: 0;
    }
`

export const LegalContainer = styled.div`
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    margin-top: 64px;
    color: ${colors.cream};

    a {
        color: ${colors.cream};
    }
    
    .text-paragraph {
        margin-left: 24px;
        flex: 1;
    }

    input[type=checkbox]{
        height: 0;
        width: 0;
        visibility: hidden;
    }

    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 40px;
        height: 20px;
        background: ${colors.cream};
        display: block;
        border-radius: 100px;
        position: relative;
        transition: color 0.3s ease-out;
        flex: 0 1 auto;
    }

    label:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        background: ${colors.primary};
        border-radius: 16px;
        transition: 0.3s;
    }

    input:checked + label {
        background: ${colors.bright01};
    }

    input:checked + label:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
    }

    label:active:after {
        width: 30px;
    }

    @media (max-width: ${breakpoints.tablet}) {
        margin-top: 32px;
    }

    @media (max-width: ${breakpoints.mobile}) {
        margin-top: 16px;
        margin-bottom 16px;
        flex-flow: column;
        .text-paragraph {
            margin-left: 0;
        }
    }
`

type FallbackType = {
    ie_image: string
}

export const StaticImageFallBack = styled.div<FallbackType>`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background-image: url(${(props) => props.ie_image});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
` 

export const rockTowerImage = {
    position: 'absolute',
    bottom: "-8px",
    right: 0,
} as const
