import styled from "styled-components";
import { colors, breakpoints } from "../../../styles/variables";

export const PurchaseWrapper = styled.div`
  grid-column: 1 / span 12;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: none;
  padding: 50px 35px 50px 35px;

  .consoleButtonRow,
  .consoleButtonColumn {
    display: flex;
    gap: 24px;
  }

  .consoleButtonRow {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  .consoleButtonRow {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .consoleButtonColumn {
    flex-direction: column;
    gap: 24px;
    width: 100%;
    margin-bottom: 25px;
  }

  .mobileLinkDivider {
    width: 134px;
    border-top: 1px solid ${colors.dark04};
    margin: auto;
  }

  .hiddenMobileLinks {
    display: none;
  }

  .card-logo {
    width: 100%;
  }

  .divider {
    width: 25%;
    margin: 2rem auto;
    border: 0;
    border-top: 2px solid ${colors.dark04};
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    margin: 0;
    
  }

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    padding: 40px 0;

    .consoleButtonRow {
      justify-content: space-between;
    }
    .consoleButtonColumn {
      margin-bottom: 0px;
    }
  }

  &.active {
    display: flex;
  }
`;

export const PurchaseCardContainer = styled.div`
  width: 40%;
  padding: 28px;
  background-color: ${colors.dark03};
  grid-column: 1 / span 12;
  box-shadow: 4px 4px 0 ${colors.primary};
  border: 1px solid ${colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;

  .buttonLine {
    text-align: center;
    width: 100%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    min-height: 490px;
    z-index: 0;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    flex-direction: column;
    padding: 24px 31px;
    gap: 24px;
    margin-left: 0px;
    z-index: 0;
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    padding: 24px;
    margin-left: 0px;
  }
`;

export const PurchaseCardHero = styled.div`
  width: 100%;
  max-width: 337px;
  height: auto;
  margin-bottom: 20px;

  [lang="zh-tw"] & {
    max-width: 240px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    margin-bottom: 0px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 0px;
  }
`;

export const PurchasePacksContainer = styled.div`
  width: 60%;
  padding-left: 48px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  flex-wrap: wrap;
  gap: 54px;

  .purchase-desktop {
    width: 100%;
    max-width: 896px;
    height: auto;
    opacity: 0;
    animation: purchase-desktop 0.5s 1;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
    border: 1px solid ${colors.primary};
  }

  @keyframes purchase-desktop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .purchase-mobile {
    width: 100%;
    max-width: 763px;
    height: auto;
    display: none;
    border: 1px solid ${colors.primary};
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    padding-top: 58px;
    padding-left: 0px;
    align-items: center;
    opacity: 0;
    animation: purchase-mobile 0.5s 1;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
  }

  @keyframes purchase-mobile {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    padding-top: 58px;
    padding-left: 0px;
    align-items: center;
    opacity: 0;
    animation: purchase-mobile 0.5s 1;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;

    .purchase-desktop {
      display: none;
    }
    .purchase-mobile {
      display: block;
    }
  }

  @keyframes purchase-mobile {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* styles for deluxe grid */
  &.featureGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    align-items: center;

    .feature-category-group {
      padding: 0 16px;
    }

    .feature-category-group:first-child {
      border-right: 1px solid rgba(0, 179, 141, 0.2);
    }

    h4 {
      font-size: 16px;
      margin-block-start: 0.75em;
      margin-block-end: 0.75em;
      animation: logo 0.8s 1;
      animation-fill-mode: forwards;
    }

    .text-paragraph {
      font-size: 13px;
      margin-block-start: 0.25em;
      margin-block-end: 0.5em;
    }

    .purchase-desktop {
      max-width: 300px;
    }

    @media (max-width: ${breakpoints.tablet}) {
      grid-template-columns: 1fr;

      .feature-category-group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 16px;

        h4 {
          grid-column: 1 / span 2;
          margin-bottom: 16px;
        }
      }

      .feature-category-group:first-child {
        border-right: 0px;
      }

      .purchase-desktop {
        max-width: initial;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  flex: 0 1 auto;
  border: 4px solid ${colors.primary};
  box-shadow: 4px 4px 0 ${colors.primary};
  align-self: start;

  @media (max-width: ${breakpoints.tablet}) {
    align-self: center;
  }
`;

export const CopyContainer = styled.div`
  text-align: center;
  > h3 {
    font-family: "ProximaNova-Regular";
    font-size: 12px;
    line-height: 156%;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: 400;
    color: ${colors.white};
    margin: 0 0 17px 0;
  }

  &.active {
    visibility: visible;
  }

  @media (min-width: ${breakpoints.tablet}) {
    &.extra-pb {
      .txt1 {
        padding-top: 16px;
        padding-bottom: 10px;
      }
      > h3 {
        padding-bottom: 16px;
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;

    > h3 {
      font-family: "ProximaNova-Regular";
      font-size: 16px;
      line-height: 156%;
      font-weight: 400;
      text-transform: uppercase;
      color: ${colors.white};
      margin: 8px 0 0 0;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    > h3 {
      font-size: 14px;
    }
  }
`;

export const TransitionCopyContainer = styled.div<{ ref: any }>`
  flex: 1;

  > .txt1 {
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;
    color: ${colors.white};
    margin: 0 0 17px 0;
    opacity: 0;
    animation: logo 0.2s 1;
    animation-fill-mode: forwards;
    animation-delay: 0.1s;
  }

  @keyframes purchase-desktop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  > .txt2 {
    font-family: "ProximaNova-Regular";
    font-size: 14px;
    line-height: 130%;
    color: ${colors.white};
    margin: 0 0 17px 0;
  }

  > h3 {
    font-size: 12px;
    line-height: 156%;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: 400;
    color: ${colors.white};
    margin: 0 0 17px 0;
  }

  &.active {
    visibility: visible;
  }

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;

    > .txt1 {
      text-align: center;
      font-size: 24px;
      margin: 0;
      margin-bottom: 16px;
    }

    > .txt2 {
      font-size: 18px;
      margin: 16px auto;
      margin-top: 0;
    }

    > h2 {
      font-family: "ProximaNova-Bold";
      font-size: 29px;
      line-height: 100%;
      text-transform: uppercase;
      color: ${colors.white};
      margin: 0 0 8px 0;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    > .txt2 {
      font-size: 14px;
    }
  }

  @keyframes logo {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const MobileStoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0px;
  }

  .img-spacing {
    margin: 0 10px;
    width: 100%;
    max-width: 85px;
    height: 25px;
  }
`;

export const FeatureContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin: 54px 0;

  @media (max-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(1, 1fr);
    margin: 24px 0;
  }
`;

export const DropdownWrapper = styled.div`
  display: none;
  flex-grow: 1;

  @media (max-width: ${breakpoints.tablet}) {
    display: block;
  }
`;

export const FeatureItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 16px 0;

  svg {
    flex: 0 1 auto;
  }

  .txt2 {
    flex: 1;
    margin: 0;
    font-family: "ProximaNova-Bold";
    font-size: 16px;
    line-height: 170%;
    color: ${colors.white};
  }

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;
