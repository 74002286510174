import styled from "styled-components";
import { breakpoints, colors, zIndices } from "../../styles/variables";

export const StyledDiv = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.bright01};
  color: ${colors.white};
  position: relative;
  font-family: "ProximaNova-Bold";
  font-size: 0.875rem;
  margin: 0;
  padding: 0.938rem 1rem 1rem 1rem;
  text-transform: uppercase;
  text-decoration: none;

  span {
    position: relative;
    z-index: 3;
  }

  p {
    margin: 0 auto;
  }

  /* a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
  } */

  &.link {
    cursor: pointer;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    background-color: ${colors.black};
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 0;
  }

  &:after {
    width: 100%;
    right: 100%;
    background-size: 100%;
    box-shadow: inset 50px 0px 50px black;
  }

  &:before {
    width: 100%;
    right: 100%;
    background: ${colors.black};
  }

  &:hover {
    &:before,
    &:after {
      opacity: 0.3;
    }

    &:after {
      right: 0%;
    }

    &:before {
      right: 100%;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    a {
      display: block;
    }
  }
`;
