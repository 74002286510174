import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

export const OverviewContainer = styled.div`
    position: relative;
    margin-top: -10vw;
    // z-index: 100;
    color: ${colors.cream};

    .text-header {
        text-align: left;
    }
`

export const ContentDiv = styled.div`
    position: relative;
    padding: 17vw 0 10vw 0;

    @media (max-width: ${breakpoints.tablet}) {
        
    }

    @media (max-width: ${breakpoints.mobile}) {
        
    }
`

type FallbackType = {
    ie_image: string
}

export const StaticImageFallBack = styled.div<FallbackType>`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background-image: url(${(props) => props.ie_image});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
` 

export const LeftContainer = styled.div`
    grid-column: 1 / span 5;
    grid-row: 2;

    > p { 
        font-family: "ProximaNova-Bold";
        color: ${colors.white};
        font-size: 32px;
        line-height: 130%;
        margin: 0;
    }

    @media (max-width: ${breakpoints.laptop}) {
        > p {
            font-size: 24px;
        }
    }

    @media (max-width: ${breakpoints.tablet}) {
        grid-column: 2 / span 6;

        > p {
            margin: 0 0 48px 0;
        }
    }

    @media (max-width: ${breakpoints.mobile}) {
        grid-column: 1 / span 2;

        > p {
            font-size: 18px;
        }
    }
`

export const RightContainer = styled.div`
    grid-column: 7 / span 6;
    grid-row: 2;

    > p {
        font-family: "ProximaNova-Regular";
        color: ${colors.cream};
        font-size: 18px; 
        line-height: 170%;
        margin: 0;
    }

    > p + p {
        margin-top: 1em;
    }

    @media (max-width: ${breakpoints.laptop}) {
        > p {  
            font-size: 16px;
        }
    }

    @media (max-width: ${breakpoints.tablet}) {
        grid-column: 2 / span 6;
        grid-row: 3;
    }

    @media (max-width: ${breakpoints.mobile}) {
        grid-column: 1 / span 2;

        > p {
            font-size: 14px;
            line-height: 150%;
        }
    }
`

export const backgroundImage = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
    width: "100%",
    height: "100%",
} as const
