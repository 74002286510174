import * as React from 'react'
import styled from 'styled-components'

const ClippedContainer = styled.div`
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0,100% 0,100% calc(100% - 2vw),0 100%);
`
export default class AngledClippedContainer extends React.Component {

    render() {
        return (
            <ClippedContainer>{this.props.children}</ClippedContainer>
        )
    }
}
