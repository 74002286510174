import styled from "styled-components";
import { colors, breakpoints } from "../../../styles/variables";

export const Slide = styled.div<{ref: any}>`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	position: absolute;
	top: 0;
	visibility: hidden;

	// First slide holds shape for the rest
	&:first-of-type {
		position: relative;
	}
	.imageBox {
		flex-basis: 63%;
		position: relative;
		width: 785px;
		height: 500px;
		overflow: hidden;
		.slideImage {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			background-size: cover;
			background-position: center center;
		}
		.vidPlayer {
			object-fit: cover;
		}
	}
	.copyBox {
		flex-basis: 37%;
		padding: 64px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;

		.txt1 {
			color: ${colors.primary};
			margin-bottom: 20px;
			opacity: 0;
		}
		.txt2 {
			color: ${colors.white};
			margin-bottom: 20px;
			opacity: 0;
		}
		.txt3 {
			color: ${colors.white};
			opacity: 0;
		}
	}

	&.active {
		visibility: visible;
	}

	@media (max-width: ${breakpoints.tablet}) {
		flex-direction: column;
		.imageBox {
			flex-basis: auto;
			width: 80%;
			margin: 0 auto;
			height: 0;
			padding-top: 51%;
		}
		.copyBox {
			flex-basis: auto;
			padding: 32px;
			.txt1, .txt2 {
				margin-bottom: 10px;
			}
		}
	}

	@media (max-width: ${breakpoints.mobile}) {
		.copyBox {
			padding: 24px 16px;
			min-height:175px;
			.txt1, .txt2 {
				margin-bottom: 10px;
			}
		}
		.imageBox {
			width: 100%;
			margin: 0;
			padding-top: 63%;
		}
	}
`