import * as React from "react";
import { PageProps, graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import RiotBar from "@riotgames/riotbar-react";
import Seo, { SeoData } from "../components/Seo/Seo";
import TopBanner, { TopBannerData } from "../components/TopBanner/TopBanner";
import Hero, { HeroData } from "../components/Hero/Hero";
import Overview, { OverviewData } from "../components/Overview/Overview";
import Features, { FeaturesData } from "../components/Features/Features";
import PreOrder, { PreOrderData } from "../components/PreOrder/PreOrder";
import Champions, { ChampionsData } from "../components/Champions/Champions";
import FullCarousel, { FullCarouselData } from "../components/FullCarousel/FullCarousel";
import Newsletter, { NewsletterData } from "../components/Newsletter/Newsletter";
import Publisher, { PublisherData } from "../components/Publisher/Publisher";
import HorizontalGallery, { HorizontalGalleryData } from "../components/HorizontalGallery/HorizontalGallery";
import VideoOverlay from "../components/VideoOverlay";
import { useSiteMetadata } from "../hooks/use-site-metadata";

import { StyleSheetManager } from "styled-components";
import griddie from "styled-griddie";
interface PageData {
  home: {
    homeSeo: SeoData;
    topBanner: TopBannerData;
    heroMarquee: HeroData;
    gameOverview: OverviewData;
    gameFeatures: FeaturesData;
    gameEditions: PreOrderData;
    gameChampions: ChampionsData;
    gameLoreCarousel: FullCarouselData;
    eversonNewsletter: NewsletterData;
    gameStudioOverview: PublisherData;
    gamesCarousel: HorizontalGalleryData;
  };
}

interface Props extends PageProps<PageData> {}

const IndexPage: React.FC<Props> = ({ data }) => {
  const [videoOpen, setVideo] = React.useState(false);

  const {
    homeSeo,
    topBanner,
    heroMarquee,
    gameOverview,
    gameFeatures,
    gameEditions,
    gameChampions,
    gameLoreCarousel,
    eversonNewsletter,
    gameStudioOverview,
    gamesCarousel,
  } = data.home;

  const { siteDomain } = useSiteMetadata();
  const { language } = useI18next();

  React.useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  const ytVideoUrl = heroMarquee.videoLink.href;
  // https://stackoverflow.com/a/8260383/2316591
  const ytVideoCode = ytVideoUrl.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/)?.[1] || "";

  const homeUrl = `https://${siteDomain}/${language}/`;

  const riotbarEnv = process.env.GATSBY_RIOTBAR_ENVIRONMENT === "staging" ? "staging" : "prod";

  return (
    <StyleSheetManager stylisPlugins={[griddie]}>
      <main className={`lang-${language}`}>
        <Seo data={homeSeo} url={homeUrl} locale={language} />
        {topBanner?.message && <TopBanner data={topBanner} />}
        <RiotBar product="everson" locale={language} environment={riotbarEnv} />
        <Hero
          data={heroMarquee}
          videoOpen={() => {
            setVideo(true);
          }}
        />
        <Overview data={gameOverview} />
        <Features data={gameFeatures} />
        <PreOrder data={gameEditions} />
        <Champions data={gameChampions} />
        <FullCarousel data={gameLoreCarousel} />
        <Newsletter data={eversonNewsletter} locale={language} />
        <Publisher data={gameStudioOverview} />
        <HorizontalGallery data={gamesCarousel} />
        {videoOpen && (
          <VideoOverlay
            ytVideoCode={ytVideoCode}
            callback={() => {
              setVideo(false);
            }}
          />
        )}
      </main>
    </StyleSheetManager>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    home: contentstackEversonHomePage(locale: { eq: $language }) {
      ...HomeSeoFragment
      ...TopBannerFragment
      ...HeroMarqueeFragment
      ...GameOverviewFragment
      ...GameFeaturesFragment
      ...GameEditionsFragment
      ...GameChampionsFragment
      ...FullCarouselFragment
      ...EversonNewsletterFragment
      ...GameStudioOverviewFragment
      ...GamesCarouselFragment
    }
    ...TranslationQueryFragment
  }
`;
