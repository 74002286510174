import React, { useRef } from "react";
import { graphql } from "gatsby";
import { ContentstackImg } from "@riotgames/wwpub-components";

import ButtonConsole from "./ButtonConsole";
import {
  PurchaseWrapper,
  PurchaseCardContainer,
  PurchasePacksContainer,
  PurchaseCardHero,
  CopyContainer,
  MobileStoreContainer,
  TransitionCopyContainer,
} from "./styles";

export const fragment = graphql`
    fragment GameEditionDetailsFragment on Contentstack_everson_home_pageGame_editions {
        gameEditions: game_editions {
            ... on Contentstack_game_edition___deluxe {
                heading
                shortHeading: short_heading
                description: description
                features: feature_section {
                    category {
                    category
                    features {
                        description
                        desktopImage: desktop_image {
                        url
                        }
                        mobileImage: mobile_image {
                        url
                        }
                    }
                    }
                    standard {
                    description
                    desktopImage: desktop_image {
                        url
                    }
                    mobileImage: mobile_image {
                        url
                    }
                    }
                }
                primaryPurchaseLinks: primary_purchase_links {
                    purchaseLink: purchase_link {
                    href
                    title
                    }
                    platform {
                    platformLogo: platform_logo {
                        url
                        svg {
                        content
                        }
                    }
                    }
                }
            }
        }
    }
`

export interface GameLogo {
    url: string;
  }

export interface PrimaryPurchaseLink {
    purchaseLink: {
        href: string;
        title: string;
    };
    platform: [
        {
            platformLogo: {
                url: string;
                svg: {
                    content: string;
                };
            };
        },
    ];
}

export interface SecondaryPurchaseLink {
    purchaseLink: {
        href: string;
        title: string;
    };
    platform: [
        {
            buttonImage: {
                url: string;
            };
        },
    ];
}

export interface PreOrderCardData {
    // editionType: string;
    heading: string;
    shortHeading: string;
    // features: Features;
    features: Feature[];
    description: string;
    // preOrderDescription: string;
    primaryPurchaseLinks: PrimaryPurchaseLink[];
    secondaryPurchaseLinks: SecondaryPurchaseLink[];
}

interface Props {
  activeItem: number;
  cardData: PreOrderCardData;
  linksHeading: string;
  index: number;
  logo: GameLogo;
}

interface State {
  visible: boolean;
}

export interface gameData {
  gameEditions: PreOrderCardData[];
  gameLogo: GameLogo;
}

interface Feature {
  standard?: null | featureItem;
  category?: null | categoryItem;
}

interface categoryItem {
  category: string;
  features: featureItem[];
}

interface featureItem {
  description: string;
  desktopImage: imageType;
  mobileImage: imageType;
}

interface imageType {
  url: string;
}

export default function PurchaseCard(props: Props, state: State) {
  const selfRef = useRef(null);

  //opens links in new window
  const linkOut = (url: string) => {
    window.open(url, "_new");
  };

  const activeClass = props.activeItem === props.index ? "active" : "";
  const copy = props.cardData;
  const linksHeadings = props.linksHeading;

  const primaryConsoleButtons = copy.primaryPurchaseLinks?.map((primaryPurchaseLink: PrimaryPurchaseLink) => (
    <ButtonConsole
      callback={() => linkOut(primaryPurchaseLink.purchaseLink.href)}
      url={primaryPurchaseLink.purchaseLink.href}
      console={primaryPurchaseLink.purchaseLink.title}
      svg={primaryPurchaseLink.platform[0].platformLogo.svg.content}
      tabData={copy.shortHeading}
      key={primaryPurchaseLink.purchaseLink.title}
      buttonLength={copy.primaryPurchaseLinks.length}
    ></ButtonConsole>
  ));

  const primaryConsoleStyles = primaryConsoleButtons.length <= 2 ? "consoleButtonColumn" : "consoleButtonRow";

  const renderFeatureImage = (feature: featureItem, key: number) => {
    return (
      <React.Fragment key={key}>
        <ContentstackImg className="purchase-desktop" image={{ url: feature.desktopImage.url }} optimized />
        <ContentstackImg className="purchase-mobile" image={{ url: feature.mobileImage.url }} optimized />
      </React.Fragment>
    );
  }

  const featureWrapperClass = copy.features.length > 1 ? "featureGrid" : "";

  const renderFeatureDescription = () => {
    if (copy.features.length == 1 && copy.features[0].standard !== null) {
      let featureDescription = copy.features[0].standard?.description;
      return <p className="txt2">{featureDescription}</p>;
    }
  };

  const renderFeatureContent = (features: Feature[]) => {
    return features.map((feature: Feature, i: number) => {
      if (feature.standard != null) {
        let standardItem = features[0]?.standard;
        if (standardItem != null) {
          return renderFeatureImage(standardItem, i);
        }
      }

      if (feature.category != null) {
        let categoryItem = feature.category;

        return (
          <div className="feature-category-group" key={categoryItem.category}>
            <h4> {categoryItem.category} </h4>
            {categoryItem.features.map((feature: featureItem, index: number) => (
              <div className="feature-category-group-item" key={feature.description}>
                {renderFeatureImage(feature, index)}
                <p className="text-paragraph">{feature.description}</p>
              </div>
            ))}
          </div>
        );
      }
    });
  };

  const extraPaddingBottomClass = copy.description ? "" : "extra-pb";

  return (
    <PurchaseWrapper className={`${activeClass}`}>
      <PurchaseCardContainer key={copy.heading}>
        <PurchaseCardHero>
          <ContentstackImg className="card-logo" image={{ url: props.logo.url }} optimized />
        </PurchaseCardHero>
        <CopyContainer className={`${extraPaddingBottomClass}`}>
          <TransitionCopyContainer ref={selfRef}>
            <h2 className="txt1">{copy.heading}</h2>
            {renderFeatureDescription()}
            {copy.description && <p className="txt2"> {copy.description} </p>}

            {/* show preOrderDescription in May 9 launch only */}
            {/* {<p className="txt2"> {copy.editionType == "Deluxe" && copy.preOrderDescription} {copy.description?.length > 0 && copy.description} </p>} */}
          </TransitionCopyContainer>
          {copy.primaryPurchaseLinks.length > 0 && <h3>{linksHeadings}</h3>}
        </CopyContainer>

        <div className={`${primaryConsoleStyles}`}>{primaryConsoleButtons}</div>
        {copy.primaryPurchaseLinks.length == 0 && <hr className="divider" />}

        {/* <hr className={`${consoleDividerStyles}`} /> */}
        {/* <MobileStoreContainer>
          <div className="consoleButtonRow">{secondaryPurchaseLinks}</div>
        </MobileStoreContainer> */}
      </PurchaseCardContainer>

      <PurchasePacksContainer className={featureWrapperClass}>
        {renderFeatureContent(copy.features)}
      </PurchasePacksContainer>
    </PurchaseWrapper>
  );
}
