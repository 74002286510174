import React from "react"
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import RightAngledClippedContainer from "../RightAngledClippedContainer";
import GridContainer from "../GridContainer";
import SectionTitle from "../SectionTitle";

import { 
    OverviewContainer, 
    backgroundImage,
    LeftContainer,
    RightContainer,
    ContentDiv,
    StaticImageFallBack,
} from "./styles"

import Gameoverview from "../../images/gameoverview-bg.png"

export const fragment = graphql`
    fragment GameOverviewFragment on Contentstack_everson_home_page {
        gameOverview: game_overview {
            headline
            rightCopyBlock: right_copy_block
            leftCopyBlock: left_copy_block
        }
    }
`

export interface OverviewData {
    headline: string
    rightCopyBlock: string
    leftCopyBlock: string
}

interface Props {
    data: OverviewData;
}

export default class Overview extends React.Component<Props> {

    render() {
        const { headline, rightCopyBlock, leftCopyBlock } = this.props.data;

        const leftCopyParagraphs = leftCopyBlock.trim().split(/\n\n+/)
        const rightCopyParagraphs = rightCopyBlock.trim().split(/\n\n+/)

        return (
            <OverviewContainer>
                <RightAngledClippedContainer>
                    <ContentDiv>
                        <StaticImageFallBack ie_image={Gameoverview}>
                            <StaticImage style={backgroundImage} objectFit="cover" src="../../images/gameoverview-bg.png" alt="background image" />
                        </StaticImageFallBack>
                        <GridContainer>
                            <LeftContainer>
                                <div className="text-header">{headline}</div>
                                <div className="text-subheader">
                                    {leftCopyParagraphs.map((p, i) => (
                                        <p key={i}>{p}</p>
                                    ))}
                                </div>
                            </LeftContainer>
                            <RightContainer>
                                {rightCopyParagraphs.map((p, i) => (
                                    <p key={i}>{p}</p>
                                ))}
                            </RightContainer>
                        </GridContainer>
                    </ContentDiv>
                </RightAngledClippedContainer>
            </OverviewContainer>
        )
    }
}
