import React from "react"
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ThemeProvider } from "styled-components";
import { ContentstackEmailForm } from "../Riot/EmailForm";
import GridContainer from "../GridContainer";
import ButtonText from "../buttons/ButtonText";

import { 
    NewsletterSection,
    backgroundImage,
    FormContainer,
    BirthdayInputContainer,
    LegalContainer,
    rockTowerImage,
    StaticImageFallBack,
} from "./styles";

import NewsletterBG from "../../images/newsletterBG.jpg";

export const fragment = graphql`
    fragment EversonNewsletterFragment on Contentstack_everson_home_page {
        eversonNewsletter: everson_newsletter {
            emailListName: email_list_name
            salesForceExternalKey: salesforce_external_key
            headline
            flavorCopy: flavor_copy
            ctaLabel: cta_label
            termsCopy: terms_copy
            emailFieldLabel: email_field_label
            emailValidationMessage: email_validation_message
            dobLabel: dob_label
            dobValidationMessage: dob_validation_message
            successMessage: success_message
            failureMessage: failure_message
        }
    }
`

export interface NewsletterData {
    emailListName: string
    salesForceExternalKey: string
    headline: string
    flavorCopy: string
    ctaLabel: string
    termsCopy: string
    emailFieldLabel: string
    emailValidationMessage: string
    dobLabel: string
    dobValidationMessage: string
    successMessage: string
    failureMessage: string
}

interface Props {
    data: NewsletterData;
    locale: string;
}

export default class Newsletter extends React.Component<Props> {

    render() {
        const {
            emailListName,
            salesForceExternalKey,
            headline,
            flavorCopy,
            ctaLabel,
            termsCopy,
            emailFieldLabel,
            emailValidationMessage,
            dobLabel,
            dobValidationMessage: _dobValidationMessage,
            successMessage,
            failureMessage,
        } = this.props.data;

        const { locale } = this.props;

        let locationPathname = "/"
        if (typeof window !== "undefined") {
            locationPathname = window.location.pathname
        }

        // American English dob format is MM/DD/YYYY.
        // Anything else is DD/MM/YYYY.
        // Use a vertical bar to add a localized dob format to
        // dobValidationMessage. D-M-Y order must be preserved.
        // Examples for specifying dob format:
        //
        // "Ingresa una fecha de nacimiento válida. | DD/MM/AAAA"
        // "Veuillez entrer une date de naissance valide. | JJ/MM/AAAA"
        // "Введите дату рождения. | ДД/ММ/ГГГГ"
        // "請輸入有效的出生日期。| 日/月/西元年 (DD/MM/YYYY)"

        let dobFormat = (locale == "en-us") ? "MM/DD/YYYY" : "DD/MM/YYYY"
        let dobValidationMessage = _dobValidationMessage

        if (locale != "en-us" && dobValidationMessage.includes("|")) {
          [dobValidationMessage, dobFormat] = dobValidationMessage.split(/ *\| */)
        }

        return (
            <NewsletterSection id="Newsletter">
                <StaticImageFallBack ie_image={NewsletterBG}>
                    <StaticImage style={backgroundImage} src="../../images/newsletterBG.jpg" alt="" />
                </StaticImageFallBack>
                <StaticImage style={rockTowerImage} src="../../images/rockTowers.png" alt="" />
                <GridContainer className="newsletterGrid">
                    <div className="titleBox">
                        <div className="text-header">{headline}</div>
                        <div className="text-miniheader">{flavorCopy}</div>
                    </div>
                    <FormContainer>
                        <ThemeProvider
                            theme={{
                                email_form_background_image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII="
                            }}
                        >
                            <ContentstackEmailForm
                                globalConfig={{
                                    date_of_birth_field_label: dobLabel,
                                    date_of_birth_format: dobFormat,
                                    email_field_label: emailFieldLabel,
                                    error_messages: {
                                        invalid_dob: dobValidationMessage,
                                        invalid_email: emailValidationMessage,
                                    },
                                    post_signup_header: successMessage,
                                }}
                                lang={this.props.locale}
                                pageConfig={{
                                    age_gating_enabled: true,
                                    email_cta: ctaLabel,
                                    email_list_name: 'Riot Forge Publication List',
                                    salesforce_external_key: 'RiotAccountData',
                                    //email_list_name: 'Riot Universe Publication List',
                                    //salesforce_external_key: 'RiotAccountData',
                                    subhead: '',
                                    title: '',
                                    successURL: `${locationPathname}#Newsletter`,
                                }}
                            />
                        </ThemeProvider>
                        <LegalContainer>
                            <p className="legal" dangerouslySetInnerHTML={{ __html: termsCopy }}/> 
                       </LegalContainer>
                    </FormContainer>
                </GridContainer>
            </NewsletterSection>
        )
    }
}
