import React from "react";
import styled from 'styled-components'
import { ContentstackImg } from "@riotgames/wwpub-components"
import { breakpoints, colors } from "../../styles/variables";

const ChampCard = styled.div`
	width: 30%;
	background: ${colors.primary};
	padding: 8px;
	cursor: pointer;
	box-shadow: 8px 8px 0 ${colors.primary};
	transition: box-shadow 0.5s ease-out;
	margin-bottom: 30px;

	.champWindow {
		width: 100%;
		padding-top: 140%;
		position: relative;
	}
	.champGrad, .champImage {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
	}
	.champGrad {
		background: linear-gradient(#7582c6 50%, #6c5452 87.5%, #3f464e 100%);
		background-size: 100% 200%;
		background-position: bottom center;
		transition: transform 0.7s ease-out, background-size 1.5s ease-out;
	}
	.champImage {
		display: flex;
		transition: transform 0.7s ease-out;
	}
	.champImage img {
		display: flex;
		flex: 1;
		margin: auto;
		width: 100%;
		height: auto;
	}
	.text-miniheader {
		color: #fff;
		padding: 24px 0 12px;
		text-align: center;
		text-transform: uppercase;
	}

	&:hover {
		box-shadow: 16px 16px 0 ${colors.bright01};

		.champGrad {
			transform: scale(0.9);
			background-size: 100% 100%;
		}
		.champImage {
			transform: scale(1.1);
		}
	}

    @media (max-width: ${breakpoints.tablet}) {
		.text-miniheader {
			padding: 12px 0;
		}
    }

    @media (max-width: ${breakpoints.mobile}) {
        width: 100%;
        .champWindow {
        	padding-top: 100%;
        }
        .champImage {
        	align-items: center;
        	img {
        		margin: unset;
        	}
        }
    }
`;

interface Props {
	imageSrc: string,
	linkHref: string,
}

export default class ButtonText extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
	}

	openURL = (event: React.MouseEvent): void => {
		window.open(this.props.linkHref, "_new");
	}

	render() {
		return(
			<ChampCard onClick={ this.openURL }>
				<div className="champWindow">
					<div className="champGrad"></div>
					<div className="champImage">
						<ContentstackImg image={{url: this.props.imageSrc}} />
					</div>
				</div>
				<div className="text-miniheader">{ this.props.children }</div>
			</ChampCard>
		);
	}
}
