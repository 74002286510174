import * as React from 'react'
import styled from 'styled-components'
import { zIndices } from "../styles/variables";

interface Props {
    className?: string;
    backgroundColor?: string;
}

const StyledContainer = styled.div`
    width: 100%;
`

const ClippedContainer = styled.div<Props>`
    position: relative;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    padding-bottom: 7vw;
    z-index: ${zIndices.lightningClip};
    background-color: ${(p) => p.backgroundColor};
    clip-path: polygon(0 0,100% 0,100% calc(100% - 16.5vw),38.25% calc(100% - 7.5vw),39% calc(100% - 10.75vw),0 calc(100% - 5.25vw));
`

const SVGLightningLines = styled.svg`
  display: block;
  position: absolute; 
  left: 0;
  bottom: -7vw;
  width: 100%;
  height: auto;   
  z-index: ${zIndices.lightningClip};
  opacity: 0.3;
`
export default class LeftLightningClippedContainer extends React.Component<Props> {

    render() {
        return (
            <StyledContainer>
                <ClippedContainer backgroundColor={this.props.backgroundColor}>{this.props.children}</ClippedContainer>
                <SVGLightningLines width="2560" height="398" viewBox="0 0 2560 398" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M-665 396.6L957.797 160L941.297 250L2561.8 9" stroke="white"/>
                    <path d="M-668 389.6L951.498 153L934.998 243L2562.3 1" stroke="white"/>
                </SVGLightningLines>
            </StyledContainer>
            
        )
      }
}
