import styled from "styled-components";
import { breakpoints, colors, zIndices } from "../../styles/variables";

export const HeroSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  .backgroundImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: center center / cover no-repeat;
  }
  .championImg {
    position: absolute;
    max-width: 800px;
    width: 31%;
    height: 100%;
    left: 0%;
    background: center center / contain no-repeat;
    z-index: ${zIndices.championImg};
    pointer-events: none;
  }
  .leftHuts {
    position: absolute;
    top: -1vw;
    left: -1vw;
    width: 50%;
    height: 100%;
    background: right center / cover no-repeat;
    opacity: 0.6;
  }
  .rightHuts {
    position: absolute;
    top: -1vw;
    right: -1vw;
    width: 50%;
    height: 100%;
    background: left center / cover no-repeat;
  }
  .centerCol {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 200px);
    max-width: 1440px;
    padding: 0 105px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .mainLogo {
      width: 45vh;
      max-width: 480px;
      margin: 20px 0;
    }
    .infoGroup {
      text-align: center;
      background: radial-gradient(closest-side, rgba(255, 255, 255, 0.45) 50%, transparent 100%);
    }
    .available {
      font-size: 20px;
      margin-bottom: 16px;
      line-height: 1.5;
      color: ${colors.lilac};
    }

    .text-paragraph {
      text-align: center;
      margin-bottom: 32px;
      margin-right: auto;
      margin-left: auto;
      max-width: 550px;
      color: ${colors.dark04};
    }

    .platforms {
      margin-bottom: 16px;
      margin-right: auto;
      margin-left: auto;
      svg {
        display: inline-block;
        width: auto;
        max-width: 130px;
        height: 33px;
        margin: 0 8px;
        position: relative;
      }
      path {
        fill: ${colors.bright01};
        transition: fill 0.3s ease-out;
      }
    }
  }
  @media (max-width: ${breakpoints.laptop}) {
    .championImg {
      left: -5%;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .centerCol {
      padding: 0 70px;

      .text-paragraph {
        font-size: 16px;
      }

      .platforms {
        max-width: 85%;
        svg {
          max-width: 120px;
          height: 30px;
          margin: 0 6px;
        }
      }
    }
    .championImg {
      width: 25%;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    .championImg {
      top: 60%;
      display: none;
    }
    .centerCol {
      padding: 0 24px;
      .mainLogo {
        width: 80%;
        max-width: 300px;
      }
      .available {
        font-size: 18px;
        line-height: 130%;
        margin-bottom: 8px;
      }
      .text-paragraph {
        line-height: 1.5em;
        margin-bottom: 8px;
      }
    }
  }
  @media (max-height: 700px) {
    .centerCol .platforms {
      display: none;
    }
  }
`;
