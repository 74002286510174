import styled from "styled-components";
import { colors, breakpoints, eases } from "../../styles/variables";


export const PreOrderSection = styled.section`
    position: relative;
    // z-index: 150;
    margin-top: -10vw;
    color: #fff;
    .instruction {
        margin-bottom: 100px;
    }
    .largeNumber {
        font-family: "ProximaNova-Black";
        font-size: 72px;
        line-height: 10%;
        color: ${colors.primary};
        margin: 0 40px 0 0;
        vertical-align: text-top;
    }
    .consoleButtonRow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;
        justify-content: center;
    }

    .topNavDropdown {
        display: none;
        grid-column: 1 / span 12;
    }
    
  .topNavTabs {
    display: block;
    grid-column: 1 / span 12;
    /* margin-bottom: 50px; */
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    -webkit-justify-content: space-evenly !important;
    height: 50px;
    position: relative;

    .tabOuter {
      width: 16.66%;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 4px solid ${colors.dark04};

      .tab-text {
        font-family: "ProximaNova-Bold", sans-serif;
        color: ${colors.dark04};
        transition: color 0.5s ease-out;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.7;
        font-weight: normal;
        text-transform: uppercase;
      }
      &.active {
        .tab-text {
          color: ${colors.primary};
        }
      }
    }
    .tabHighlight {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 16.66%;
      height: 4px;
      background-color: ${colors.primary};
      transition: transform 0.5s ${eases.inOut};
    }
  }

    @media (max-width: ${breakpoints.mobile}) {
        .largeNumber {
            display: block;
            line-height: 100%;
        }
        .instruction {
            margin-bottom: 50px;
        }
        .consoleButtonRow {
            justify-content: space-between;
        }
        .topNavDropdown {
            display: block;
        }
        .topNavTabs{
            display: none;
        }
    }
`

export const SectionContent = styled.div`
    grid-column: 1 / span 12;
    position: relative;
    margin-top: 50px;
    
    .preorder-background {
        z-index: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid ${colors.primary};
        opacity: 0.5;
        background: linear-gradient(180deg, #25135C 0.01%, #343984 100%);
    } 

    @media (max-width: ${breakpoints.mobile}) {
        margin-top: 0;

        .preorder-background {
            border: none;
            background: none;
        }
    }
`;

type FallbackType = {
    ie_image: string
}

export const StaticImageFallBack = styled.div<FallbackType>`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background-image: url(${(props) => props.ie_image});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
`

export const ContentDiv = styled.div`
    position: relative;
    padding: 17vw 0 40px 0;

    @media (min-width: ${breakpoints.mobileS}) {
       padding-bottom: 100px;
    }
    
    @media (min-width: ${breakpoints.tablet}) {
       padding-bottom: 200px;
    }
`

export const PlatformContainer = styled.div`
    background-color: ${colors.dark03};
    grid-row: 2;
    grid-column: 1 / span 12;
    border: 4px solid ${colors.primary};
    box-shadow: 8px 8px 0 ${colors.primary};
    display: flex;
    flex-direction: column;
    padding: 72px;
    margin: 0 0 56px 0;

    @media (max-width: ${breakpoints.tablet}) {
        grid-column: 1 / span 8;
        padding: 32px;
    }

    @media (max-width: ${breakpoints.mobile}) {
        grid-column: 1 / span 2;
        padding: 24px;
    }
`

export const DropdownWrapper = styled.div`
    display: none;
    flex-grow: 1;

    @media (max-width: ${breakpoints.tablet}) {
        display: block;   
    }
`

export const PlatformSelection = styled.div`
    height: 84px;
    background-color: ${colors.neutral02};
    border: 4px solid ${colors.primary};
    box-shadow: 4px 4px 0 ${colors.primary};
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${breakpoints.tablet}) {
        display: none;   
    }
`

export const BundleContainer = styled.div`
    background-color: ${colors.dark03};
    grid-row: 3;
    grid-column: 1 / span 12;
    border: 4px solid ${colors.primary};
    box-shadow: 8px 8px 0 ${colors.primary};
    display: flex;
    flex-direction: column;
    padding: 72px;

    @media (max-width: ${breakpoints.tablet}) {
        grid-column: 1 / span 8;
        padding: 32px;
    }

    @media (max-width: ${breakpoints.mobile}) {
        grid-column: 1 / span 2;
        padding: 24px;
    }
`

export const backgroundImage = {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 0,
    width: "100vw",
    height: "100vh",
} as const
