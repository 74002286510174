import styled from "styled-components";
import { colors, breakpoints } from "../../styles/variables";

export const PublisherSection = styled.section`
    position: relative;
    padding: 100px 0;
    .buttonRow {
        grid-column: 1 / span 12;
        grid-row: 4 / span 1;
        text-align: center;
        margin: 60px 0 0 0;
    }
    @media (max-width: ${breakpoints.tablet}) {
        padding: 88px 0;
        .buttonRow {
            grid-column: 2 / span 6;
        }
    }
    @media (max-width: ${breakpoints.mobile}) {
        padding: 44px 0;
        .buttonRow {
            grid-column: 1 / span 2;
            margin: 30px 0 0 0;
        }
    }
`

type FallbackType = {
    ie_image: string
}

export const StaticImageFallBack = styled.div<FallbackType>`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background-image: url(${(props) => props.ie_image});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
` 

export const backgroundImage = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
    width: "100%",
    height: "100%",
} as const

export const LogoContainer = styled.div`
    grid-column: 1 / span 12;
    text-align: center;
    margin: 0 0 80px 0;

    img, svg { 
        width: 654px;
        max-width: 100%;
    }

    @media (max-width: ${breakpoints.tablet}) {
        grid-column: 2 / span 6;
        margin: 0 0 40px 0;

        img, svg { 
            width: 550px;
        }
    }

    @media (max-width: ${breakpoints.mobile}) {
        grid-column: 1 / span 2;
    }
`

export const LeftContainer = styled.div`
    grid-column: 1 / span 5;
    grid-row: 2 / span 1;

    .text-miniheader { 
        color: ${colors.primary};
    }

    p {
        line-height: 130%;
        margin: 0;
    }
    p + p { 
        margin-top: 1em;
    }

    @media (max-width: ${breakpoints.tablet}) {
        grid-column: 2 / span 6;
        margin-bottom: 30px;
    }

    @media (max-width: ${breakpoints.mobile}) {
        grid-column: 1 / span 2;
    }
`

export const RightContainer = styled.div`
    grid-column: 7 / span 6;
    grid-row: 2 / span 1;

    p {
        line-height: 170%;
        margin: 0;
    }

    p + p {
        margin-top: 1em;
    }

    .text-paragraph {
        color: ${colors.cream};
        margin-bottom: 24px;
    }

    @media (max-width: ${breakpoints.tablet}) {
        grid-column: 2 / span 6;
        grid-row: 3 / span 1;
    }

    @media (max-width: ${breakpoints.mobile}) {
        grid-column: 1 / span 2;
    }
`
