import styled from "styled-components";
import { colors, breakpoints, zIndices } from "../../styles/variables";

export const ChampionSection = styled.section`
	position: relative;
	padding-bottom: 32px;
	z-index:2;
	margin-bottom:-2px;
	background: center bottom / 100% auto no-repeat;
	.birdsImage {
		position: absolute;
		top: 10vw;
		right: 0;
	}
	.contentDiv {
		grid-column: 1 / span 12;
		display: flex;
		flex-direction: column;
	}
	.fluteImage {
		width: 150px;
		z-index: ${zIndices.hideBehind};
		margin: 0 auto;
	}
	.titleBox {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 90px 0;
		z-index: ${zIndices.hideBehind};


		.text-header {
			color: ${colors.bright01};
			position: relative;
		}

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

	}
	.champColumns {
		width: 100%;
		display: flex;
		flex-wrap: nowrap;
		column-gap: 20px;
		justify-content: space-between;
	}

	@media (max-width: ${breakpoints.tablet}) {
		.champColumns {
			flex-wrap: wrap;
		}	
	}
	@media (max-width: ${breakpoints.mobile}) {
		.contentDiv {
			grid-column: 1 / span 2;
		}
		.fluteImage {
			width: 100px;
		}
		.champColumns {
			flex-direction: column;
			.champCard {
				width: 100%;
			}
		}
	}
`

type FallbackType = {
	ie_image: string
}

export const StaticImageFallBack = styled.div<FallbackType>`
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			content: "";
			width: 100%;
			height: 100%;
			background-image: url(${(props) => props.ie_image});
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
	}
` 
