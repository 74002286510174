import * as React from 'react'
import styled from 'styled-components'

const ClippedContainer = styled.div`
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
`
export default class LeftAngledClippedContainer extends React.Component {

    render() {
        return (
            <ClippedContainer>{this.props.children}</ClippedContainer>
        )
    }
}
