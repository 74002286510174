import React, { useState } from "react"
import _ from "lodash";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Select from "react-select";
import { components } from "react-select";
import LeftAngledClippedContainer from "../LeftAngledClippedContainer";
import GridContainer from "../GridContainer";
import PurchaseCard from "../molecules/PurchaseCard/PurchaseCard";
import { GameLogo, PreOrderCardData } from "../molecules/PurchaseCard/PurchaseCard";
import { ButtonConsole } from "./ButtonConsole";
import SectionTitle from "../SectionTitle";
import { SelectStyles } from "./Select";

import {
    PreOrderSection,
    ContentDiv,
    SectionContent,
    PlatformContainer,
    PlatformSelection,
    BundleContainer,
    backgroundImage,
    DropdownWrapper,
    StaticImageFallBack
} from "./styles"

import PreOrderBG from "../../images/preOrderBG.jpg"
import LeftLightningClippedContainer from "../LeftLightningClippedContainer";

export const fragment = graphql`
    fragment GameEditionsFragment on Contentstack_everson_home_page {
        gameEditions: game_editions {
            sectionTitle: section_title
            bodyCopy: body_copy
            choosePlatformCopy: choose_platform_copy
            chooseEditionCopy: choose_edition_copy
            gameLogo: game_logo {
                url
            }
            ...GameEditionDetailsFragment
        }
    }
`
export interface PreOrderData {
    sectionTitle: string
    bodyCopy: string
    choosePlatformCopy: string
    chooseEditionCopy: string
    gameLogo: GameLogo
    gameEditions: PreOrderCardData[]
}

interface Props {
    data: PreOrderData;
    hideSectionTitle?: boolean;
}

type State = { platform: string; activeItem: number };

const CONSOLES: Array<string> = ["ps", "xbox", "steam", "switch", "epic", "gog"];

export default class PreOrder extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            platform: "",
            activeItem: 0,
        };
    }

    dropdownReturnValue = (value: String) => {
        // Value to use from dropdown
    }

    // 1. User selects platform
    platformSelected = (platform: string) => {
        if (this.state.platform === platform) {
            this.setState({ platform: "" });
        } else {
            this.setState({ platform });
        }
    }

    // 2. User selects edition
    preorder = (url: string) => {
        window.open(url, "_new");
    }

    tabWidth = `${(100 / this.props.data.gameEditions.length).toFixed(1)}%`;

    //set tab names
    renderTabs = () => {
        return this.props.data.gameEditions.map((tab: { shortHeading: string }, index: number) => {
            const activeClass = index === this.state.activeItem ? "active" : "";
            return (
                <div
                    className={`tabOuter ${activeClass}`}
                    style={{ width: this.tabWidth }}
                    key={`slide-${index}`}
                    onClick={() => this.setState({ activeItem: index })}
                >
                    <div className="tab-text">{tab?.shortHeading}</div>
                </div>
            );
        });
    };

    //render edition info based on active tab
    renderEdition = () => {
        return this.props.data.gameEditions.map((item: PreOrderCardData, index: number) => {
            return (
                <PurchaseCard
                    linksHeading={this.props.data.choosePlatformCopy}
                    activeItem={this.state.activeItem}
                    cardData={item}
                    logo={this.props.data.gameLogo}
                    index={index}
                    key={`game-edition-${index}`}
                ></PurchaseCard>
            );
        });
    };


    CustomDropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <g clipPath="url(#clip0_5979_23040)">
                        <path d="M12.2051 19.5L22.5974 8.7L1.81277 8.7L12.2051 19.5Z" fill="#00CBEA" />
                        <path d="M12.2051 19.5L22.5974 8.7L1.81277 8.7L12.2051 19.5Z" fill="#00CBEA" />
                    </g>
                    <defs>
                        <clipPath id="clip0_5979_23040">
                            <rect width="24" height="24" fill="white" transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 24.2051 24.5)" />
                        </clipPath>
                    </defs>
                </svg>
            </components.DropdownIndicator>
        );
    };

    render() {
        const {
            sectionTitle,
            bodyCopy,
            choosePlatformCopy,
            chooseEditionCopy,
            gameEditions,
        } = this.props.data;

        //mobile dropdown
        const options = gameEditions.map((item, index) => ({
            label: item.shortHeading,
            value: index,
            index: index,
        }));

        return (
            <PreOrderSection id="preorder-section">
                <LeftAngledClippedContainer>
                    <ContentDiv>
                        <StaticImageFallBack ie_image={PreOrderBG}>
                            <StaticImage style={backgroundImage} objectFit="cover" src="../../images/preOrderBG.jpg" alt="background image" />
                        </StaticImageFallBack>
                        <GridContainer>
                            {!this.props.hideSectionTitle && (
                                <SectionTitle colorTheme="light" title={sectionTitle} id="preorder-section-title">
                                    {chooseEditionCopy}
                                </SectionTitle>
                            )}

                            <SectionContent>
                                <div className="preorder-background"></div>
                                {/* Mobile Dropdown */}
                                <div className="topNavDropdown">
                                    <Select
                                        id="long-value-select"
                                        instanceId="long-value-select"
                                        options={options}
                                        value={options[this.state.activeItem]}
                                        styles={SelectStyles(false)}
                                        components={{ DropdownIndicator: this.CustomDropdownIndicator }}
                                        onChange={(e: any) => {
                                            this.setState({ activeItem: e.index });
                                        }}
                                    />
                                </div>

                                {/* Desktop Tabs */}
                                <div className="topNavTabs">
                                    {this.renderTabs()}
                                    <div
                                        className="tabHighlight"
                                        style={{ width: this.tabWidth, transform: `translateX(${this.state.activeItem * 100}%)` }}
                                    ></div>
                                </div>
                                {this.renderEdition()}
                            </SectionContent>

                        </GridContainer>
                    </ContentDiv>
                </LeftAngledClippedContainer>
            </PreOrderSection>
        )
    }
}
