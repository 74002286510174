import React from "react"
import { graphql, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ContentstackImg } from "@riotgames/wwpub-components"
import ButtonText from "../buttons/ButtonText";
import GridContainer from "../GridContainer";

import {
    PublisherSection,
    backgroundImage,
    LogoContainer,
    LeftContainer,
    RightContainer,
    StaticImageFallBack,
} from "./styles"

import BackgroundImage from '../../images/publisherBG.jpg'

export const fragment = graphql`
    fragment GameStudioOverviewFragment on Contentstack_everson_home_page {
        gameStudioOverview: game_studio_overview {
            headline
            studioLogo: studio_logo {
                url
            }
            leftCopy: left_copy
            rightCopy: right_copy
            exploreCta: explore_cta {
                title
                href
            }
        }
    }
`

export interface PublisherData {
    headline: string
    studioLogo: {
        url: string
    }
    leftCopy: string
    rightCopy: string
    exploreCta: {
        title: string
        href: string
    }
}

interface Props {
    data: PublisherData;
}

export default class Publisher extends React.Component<Props> {

    render() {
        const { headline, studioLogo, leftCopy, rightCopy, exploreCta } = this.props.data;

        const leftCopyParagraphs = leftCopy.trim().split(/\n\n+/)
        const rightCopyParagraphs = rightCopy.trim().split(/\n\n+/)

        return (
            <PublisherSection>
                <StaticImageFallBack ie_image={BackgroundImage}>
                    <StaticImage style={backgroundImage} src="../../images/publisherBG.jpg" alt="" />
                </StaticImageFallBack>
                <GridContainer>
                    <LogoContainer>
                        <ContentstackImg image={{url: studioLogo.url}} />
                    </LogoContainer>
                    <LeftContainer>
                        <div className="text-miniheader">
                            {leftCopyParagraphs.map((p, i) => (
                                <p key={i}>{p}</p>
                            ))}
                        </div>
                    </LeftContainer>
                    <RightContainer>
                        <div className="text-paragraph">
                            {rightCopyParagraphs.map((p, i) => (
                                <p key={i}>{p}</p>
                            ))}
                        </div>
                    </RightContainer>
                    <div className="buttonRow">
                        <ButtonText colorTheme="blue" callback={() => navigate(exploreCta.href)}>{exploreCta.title}</ButtonText>
                    </div>
                </GridContainer>
            </PublisherSection>
        )
    }
}
